import HomeOne from './HomeOne'
import Trails from './Trails'
import Contact from './Contact'

const onClick = () => {
  window.open('https://wa.me/5581983737777', '_blank')
}

const routes = [
  { path: '/', component: <HomeOne linkRegister={onClick} /> },
  { path: '/trails', component: <Trails linkRegister={onClick} /> },
  { path: '/contact', component: <Contact linkRegister={onClick} /> },
]

export default routes
